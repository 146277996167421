import {inject, Injectable} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  /** Services */
  private toastrService: ToastrService = inject(ToastrService);

  constructor() {

  }

  public success(message: string): void {
    this.toastrService.success(message, 'Sucesso!!', { disableTimeOut: false, timeOut: 6000 });
  }

  public error(message: string): void {
    this.toastrService.error(message, 'Atenção!', { disableTimeOut: false, timeOut: 6000 });
  }

  public errorArray(error:Array<any>): void {
    Object.entries(error).map(obj =>  {
      obj[1].map(msg => {
        this.toastrService.error(msg, 'Atenção!', { disableTimeOut: false, timeOut: 6000 });
      });
    });
  }
}
